import { Box } from '@mui/material';
import React from 'react';
import MultiLinkButton, { MultiButtonOptions } from './multi-link-button';

const loginOptions: MultiButtonOptions[] = [
  {
    text: 'AWS Single Sign On',
    href: 'https://myapps.microsoft.com/signin/47b0914b-57da-4d28-a002-e98ed619c994?tenantId=d8986036-5135-44ac-b219-a64ab43409d5',
    tooltip: 'Opens AWS Single Sign On page (opens a new tab)',
    enabled: true
  },
  {
    text: 'AWS Single Sign On (legacy)',
    href: 'https://myapps.microsoft.com/signin/ee2a82c2-16c2-4309-b034-e9313a8d2a8b?tenantId=d8986036-5135-44ac-b219-a64ab43409d5',
    tooltip: 'Opens AWS Single Sign On page (opens a new tab)',
    enabled: true
  },
  {
    text: 'AWS Data Lake Prod Personal',
    href: ' https://myapps.microsoft.com/signin/d44b0e21-81da-473b-bad6-6a85538ba084?tenantId=d8986036-5135-44ac-b219-a64ab43409d5',
    tooltip: 'Opens personal SSO login for AWS Data Lake Production (opens a new tab)',
    enabled: true
  },
  {
    text: 'AWS Data Lake Prod Public',
    href: 'https://myapps.microsoft.com/signin/b179207f-7e81-437b-95a6-f642f9fa1739?tenantId=d8986036-5135-44ac-b219-a64ab43409d5',
    tooltip: 'Opens public SSO login for AWS Data Lake Production (opens a new tab)',
    enabled: true
  },
  {
    text: 'AWS Data Lake Int Personal',
    href: ' https://myapps.microsoft.com/signin/c5b1841c-a2cf-46e5-91f5-749c8bcd80f8?tenantId=d8986036-5135-44ac-b219-a64ab43409d5',
    tooltip: 'Opens personal SSO login for AWS Data Lake Integration (opens a new tab)',
    enabled: true
  },
  {
    text: 'AWS Data Lake Int Public',
    href: ' https://myapps.microsoft.com/signin/08619765-16e0-4e68-bb8a-ca519db82f90?tenantId=d8986036-5135-44ac-b219-a64ab43409d5',
    tooltip: 'Opens public SSO login for AWS Data Lake Integration (opens a new tab)',
    enabled: true
  }
];

function Links() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        textTransform: 'uppercase',
        color: 'inherit',
        gap: 2
      }}
    >
      <MultiLinkButton options={loginOptions} />
    </Box>
  );
}

export default Links;
