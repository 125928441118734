import { PublicClientApplication, Configuration } from '@azure/msal-browser';

let msalInstance: PublicClientApplication;

export function getMsalInstance(msalConfig: Configuration) {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(msalConfig);
  }
  return msalInstance;
}

export default getMsalInstance;
